import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';


const indicators = [
  "MACD", "RSI", "EMA", "MFI", "ADX", "SMA"
]

const logoStyle = {
  width: '100px',
  height: '80px',
  margin: '0 32px',
  opacity: 0.7,
  fontSize: 32,
  fontWeight: "800",
  textAlign: 'center' as 'center'
};

export default function LogoCollection() {

  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      <Typography
        component="p"
        variant="subtitle1"
        align="center"
        color="text.secondary"
        fontWeight="700"
        sx={{ mb: 6 }}
      >
        Trusted Technical Indicators
      </Typography>
      <Grid container justifyContent="center" sx={{ mt: 0.5, opacity: 0.6 }}>
        {indicators.map((ti, index) => (
          <Grid item key={index}>
          <Typography style={logoStyle}>{ti}</Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
